export const formatTime = (time: string) => {
	return new Date(time).toLocaleTimeString("en-US", {
		timeStyle: "short",
		hour12: true,
	});
};

export const getCurrentDate = () => {
	return new Date().toISOString().split("T")[0];
};

export const getCurrentYear = () => {
	return new Date().getFullYear();
};

export const formatDate = (dateString: string | null) => {
	if (!dateString) {
		return "";
	}
	const today = getCurrentDate();
	if (dateString === today) {
		return "Today";
	} else {
		const date = new Date(dateString);
		return date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
	}
};
